#contact {
    padding: 0 20px;
    margin: auto;
}
#contact-title {
    height: 48px;
    display: flex;
    align-items: center;
    font-family: var(--font-en-bold);
    font-size: 48px;
    padding: 0 0 0 15px;
    border-left: 8px solid black;
    margin: 80px 0 20px 0;
}
#contact-tooltip {
    font-family: var(--font-en), var(--font-kr);
    font-weight: 400;
    font-size: 16px;
    margin: 0 0 30px 0;
}
#contact-form {
    display: flex;
    flex-direction: column;
    margin: 0 0 80px 0;
}
#contact-form > label {
    max-width: 500px;
    align-items: center;
    display: flex;
    font-family: var(--font-kr);
    font-size: 16px;
    font-weight: 700;
    margin: 20px 0 5px 0;
}
#contact-form > label > span{
    color: #ff5555;
    margin: 0 0 0 5px;
}
#contact-form > label > #email-error {
    font-size: 12px;
    color: #ff5555;
    margin: 0 0 0 auto;
}
#contact-form > label > #email-error.error {
    color: #992222;
}
#contact-form > label > #email-error.correct {
    color: #55dd55;
}
#contact-form input,
#contact-form select {
    width: 100%;
    max-width: 500px;
    height: 36px;
    padding: 3px 5px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    box-sizing: border-box;
}
#contact-form > div {
    width: 100%;
    max-width: 500px;
    display: flex;
    gap: 15px;
}
#contact-form > div > div {
    flex: 1;
}
#contact-form input[type="email"] {
    width: 100%;
    margin: 0 0 2px 0;
}
#contact-form > div > div > span {
    font-size: 12px;
}
#contact-form textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    margin: 0 0 15px 0;
}
#contact-form input[type="submit"] {
    width: 120px;
    font-family: var(--font-kr);
    font-size: 14px;
    background: #f5f5f5;
}