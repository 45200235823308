#slider-area {
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    background: gray;
    margin: 20px auto 120px auto;
    overflow: hidden;
}
@media (max-width: 1080px) {
    #slider-area {
        width: 100%;
        margin: 20px auto 120px auto;
    }
}
@media (max-width: 680px) {
    #slider-area {
        width: 100%;
        margin: 20px auto 60px auto;
    }
}
.slider-button {
    grid-row: 1/2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    text-shadow: 0 0 2px black;
    cursor: pointer;
    z-index: 1;
}
.slider-button:hover {
    color: var(--main-color);
}
#slider-prev {
    grid-column: 1/2;
}
#slider-next {
    grid-column: 3/4;
}
#slider-dots {
    grid-row: 1/2;
    grid-column: 2/3;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    grid-gap: 5px;
    padding: 10px 0;
    pointer-events: none;
    z-index: 2;
}
.dot {
    width: 15px;
    height: 15px;
    border: 3px solid white;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0 0 5px #0000003f;
}
.dot.active {
    background: white;
}
@media (max-width: 420px) {
    .slider-button {
        font-size: 12px;
    }
    .dot {
        width: 8px;
        height: 8px;
        border: 2px solid white;
    }
}
#container {
    grid-column: 1/4;
    grid-row: 1/2;
    width: calc(100% * var(--slider-number));
    height: fit-content;
    display: flex;
    margin: 0 0 0 -100%;
}
.cell {
    flex:none;
    width: calc(100% / var(--slider-number));
    object-fit: cover;
    cursor: pointer;
}
.sub-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 80px auto;
}
.main-title {
    font-family: var(--font-en-bold);
    margin: 0;
}
.main-hr {
    width: 64px;
    margin: 5px 0 30px 0;
}
#social-icons {
    width: 80%;
    min-width: 300px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
}
.social-icon {
    flex: none;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 36px;
    border-radius: 36px;
    margin: 0 auto;
    text-decoration: none;
    cursor: pointer;
}
#social-instagram {
    font-size: 42px;
    background:  radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}
#social-x {
    background:  black;
}
#social-youtube {
    background:  #D80B0B;
}
#social-threads {
    background:  black;
}
#social-twitch {
    background:  #753C96;
    padding: 3px 0 0 3px;
}
#social-chzzk {
    background:  black;
}
#space-icons {
    width: calc(100% - 80px);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 40px;
    justify-items: center;
    margin: 0 40px;
}
@media (max-width: 1000px) {
    #space-icons {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 680px) {
    #space-icons {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        margin: 0;
    }
}
@media (min-width: 680px) {
    #social-icons {
        grid-template-columns: repeat(6, 1fr);
    }
}
.space-icon {
    display: flex;
    flex-direction: column;
	text-decoration: none;
	color: inherit;
}
.space-title {
    width: 280px;
    height: 94px;
    display: flex;
    border-radius: 47px 47px 5px 47px;
}
.space-img {
    flex: none;
    width: 80px;
    height: 80px;
    display: flex;
    background: white;
    border-radius: 40px;
    margin: 7px;
    box-sizing: border-box;
}
.space-img img {
    width: 60px;
    height: 60px;
    margin: auto;
    object-fit: contain;
}
.space-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin: 0 0 0 15px;
}
.space-text span {
    font-size: 14px;
    line-height: 14px;
}
.space-text span strong{
    font-family: var(--font-en-bold); 
}
.space-text h4 {
    font-family: var(--font-en-bold);
    font-size: 26px;
    line-height: 26px;
    margin: 0;
}
.space-text img {
    width: 140px;
    object-position: left;
}
.space-tooltip {
    width: 280px;
    font-family: var(--font-en-bold);
    font-size: 14px;
    line-height: 24px;
    text-align: right;
}
#space-space {
    background: #3A384C;
}
#space-planet {
    background: linear-gradient(90deg, #de7299, #f3d296);
}
#space-umia {
    background: linear-gradient(90deg, #1c305b, #22b1b9);
}
#space-beaters {
    background: #2c2b2c;
}
#space-mahjong {
    background: #6ebc79;
}
#space-empty {
    background: grey;
}
#space-space .space-img img,
#space-empty .space-img img {
    width: 50px;
    height: 50px;
}
#space-empty .space-img {
    filter: grayscale(1);
}