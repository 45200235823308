.notfound {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: var(--font-en-bold);
    background: linear-gradient(to right, #3a384c, #3a384c);
}
.notfound > div {
    width: 100%;
    max-width: 600px;
}
.notfound > div:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.notfound > div:first-child > div {
    margin: 0 0 50px 0;
}
.notfound > div:first-child > div > span {
    font-size: 24px;
}
.notfound > div:first-child > div > h2 {
    font-size: 90px;
    line-height: 72px;
    margin: 0;
}
.notfound > div:first-child > div > h3 {
    font-size: 54px;
    line-height: 42px;
    margin: 0;
}
.notfound > div:first-child > p {
    font-family: var(--font-kr);
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin: 0;
}
.notfound > div:last-child {
    flex: none;
    height: 80px;
    padding: 15px 0;
}
.notfound > div:last-child > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
