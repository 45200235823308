#ellix-main {
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
@media (max-width: 400px) {
    #ellix-main {
        height: unset;
        margin: 80px auto 0 auto;
    }
}
#ellix-main > div {
    width: 100%;
    flex: 200px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
#ellix-main > div > img {
    object-fit: contain;
}
#ellix-main > div:first-child > img {
    width: 100px;
}
#ellix-main > div:nth-child(2) > img {
    width: 200px;
}
#ellix-main > div:nth-child(3) > img {
    width: 50px;
}
#ellix-catch {
    margin: 120px auto;
}
#ellix-catch h3 {
    font-family: var(--font-en);
    font-weight: 100;
    font-size: 48px;
    text-align: center;
    color: #3a384c;
    margin: 0 0 100px 0;
}
#ellix-catch h2 {
    font-family: var(--font-en-bold);
    font-size: 72px;
    text-align: center;
    line-height: 60px;
    color: #ffabbf;
    margin: 0;
}
#ellix-catch p {
    font-size: 24px;
    text-align: center;
    color: #3a384c;
    margin: 0;
}
#ellix-catch span {
    font-family: var(--font-en-bold);
    color: #ffabbf;
}

#ellix-info {
    width: 100%;
    max-width: 800px;
    padding: 0 20px 200px 20px;
    margin: 0 auto;
}
.ellix-title {
    font-family: var(--font-en-bold);
    font-size: 48px;
    color: #ffabbf;
    margin: 0 0 40px 0;
}
.ellix-title span {
    color: #3a384c;
}
.ellix-item-list {
    list-style: none;
    padding: 0;
    margin: 0 0 80px 0;
}
.ellix-item-list li {
    display: flex;
    margin: 7px 0;
}
.ellix-item-header {
    flex: 200px 0;
    font-family: var(--font-en-bold);
    font-size: 24px;
    color: #ffabbf;
}
.ellix-item-body {
    font-size: 22px;
    line-height: 28px;
    color: #3a384c;
}
.ellix-item-body > strong {
    font-family: var(--font-en-bold);
}
.ellix-item-body > a {
    color: #3a384c;
    text-decoration: none;
}
.ellix-item-body > a:hover {
    color: #ffabbf;
}
.ellix-item-body > a > i {
    font-size: 16px;
}
@media (max-width: 400px) {
    .ellix-item-header {
        flex: none;
        width: 120px;
        font-size: 16px;
        line-height: 24px;
    }
    .ellix-item-body {
        font-size: 16px;
        line-height: 24px;
        word-break: keep-all;
    }
}