#works-nav {
    max-width: 100vw;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    background: var(--main-color);
}
@media(max-width: 680px) {
    #works-nav {
        grid-gap: 10px;
    }
}
#works-nav input {
    display: none;
}
#works-nav label {
    font-size: 17px;
    color: white;
    cursor: pointer;
}
#works-nav input:checked + label {
    font-family: var(--font-en-bold);
}
#works-nav + div {
    max-width: 100vw;
}
.waves {
    position: relative;
    width: 100%;
    height: 20px;
    transform: rotate(180deg);
}
/* Animation */
.parallax > use {
    animation: move-forever 25s cubic-bezier(0.55,.5,0.45,.5) infinite;
}
.parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}
.parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}
.parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}
.parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}
@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}
#works-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin: 20px auto;
}
.work-wrap {
    position: relative;
    width: 100%;
    cursor: pointer;
}
.work-wrap::before {
    content: "\f002";
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "FontAwesome";
    font-size: 32px;
    background-color: #00000077;
    z-index: 1;
    opacity: 0;
    transition: 0.3s opacity;
}
.work-wrap:hover::before {
    opacity: 1;
}
.work-wrap::after {
    content: "";
    display: block;
    padding: 0 0 100% 0;
}
.work {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.work img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s transform;
}
.work:hover img{
    transform: scale(1.2);
}

#work-popup-wrap {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: #00000099;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s all;
}
#work-popup-wrap.active {
    opacity: 1;
    pointer-events: all;
}
#work-popup {
    width: 100%;
    max-width: var(--content-width);
    height: 100%;
    max-height: 900px;
    font-family: var(--font-en);
    background: white;
    margin: auto;
}
#work-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: flex-end;
}
#work-title-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 20px;
}
#work-title {
    font-family: var(--font-en-bold);
    font-size: 22px;
    line-height: 24px;
    margin: 0;
}
#work-subtitle {
    font-size: 14px;
    padding: 0 0 5px 0;
    border-bottom: 1px solid #cccccc;
    margin: 0;
}
#work-close {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-en-bold);
    font-size: 90px;
    cursor: pointer;
}
#work-close:hover {
    color: white;
    background: black;
}
#work-body {
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 20px 100px 20px;
    margin: 10px 0;
    overflow: scroll;
}
#work-works {
    width: 100%;
}
.work-media {
    width: 100%;
    margin: 0 0 15px 0;
}
.work-media.youtube {
    position: relative;
    width: 100%;
    padding: 0 0 56.25% 0;
}
.work-media > img {
    width: 100%;
}
.work-media > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    overflow: visible;
}
#work-name {
    font-family: var(--font-en-bold);
    font-size: 26px;
    line-height: 30px;
    margin: 40px 0 0 0;
}
#work-subname {
    font-size: 16px;
    margin: 0 0 60px 0;
}
.work-info-header {
    font-family: var(--font-en-bold);
    font-size: 18px;
    margin: 30px 0 0 0px;
}
.work-info {
    font-family: var(--font-en), var(--font-kr), 300;
    font-size: 18px;
    margin: 0 0 0 0px;
}