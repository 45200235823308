#main-header {
	width: 100%;
	display: flex;
	flex-direction: column;
}
#title {
	height: 80px;
	margin: 10px auto;
}
#title img {
	height: 80%;
	margin-top: 5px;
    object-fit: contain;
}
#main-nav-wrapper {
	width: 100%;
	height : 50px;
	display: flex;
	justify-content: center;
	background: var(--dark-color);
	border-bottom: 3px solid var(--main-color);
	z-index: 99;
}
#main-nav {
	width: 100%;
	max-width: var(--content-width);
	height : 100%;
	display: flex;
	align-items: center;
	grid-gap: 30px;
	padding: 0 20px;
	box-sizing: border-box;
}
#main-nav > a {
	width: fit-content;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	line-height: 17px;
	text-decoration: none;
	color: #d0d0d0;
	transition: 0.1s all;
}
#main-nav > a:hover {
	color: white;
}
#main-nav > a.active {
	color: var(--main-color);
}
#main-nav > h2 {
	font-size: 22px;
	font-weight: normal;
	color: white;
	margin: 0 0 0 auto;
}
#main-nav > h2 strong {
	font-family: var(--font-en-bold);
	font-weight: 600;
}
#main-header.abs {
	margin: 0 0 50px 0;
}
#main-header.abs #main-nav-wrapper {
	position: absolute;
	left: 0;
	top: 0;
}
@media (max-width: 680px) {
	#main-nav {
		flex: 1;
		grid-gap: 20px;
		justify-content: center;
	}
	#main-nav > h2 {
		display: none;
	}
}