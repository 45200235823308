@import url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo.css');
@font-face {
	font-family: "Nexa-Bold";
	src: url("./font/Nexa-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "Nexa-Light";
	src: url("./font/Nexa-Light.ttf") format("truetype");
}
@font-face {
	font-family: "Apple SD Gothic Neo";
	src: url("https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo.css") format("truetype");
}
:root {
	--main-color: #ffabbf;
	--dark-color: #3a384c;
	--font-en: "Nexa-Light";
	--font-en-bold: "Nexa-Bold";
	--font-kr: "Apple SD Gothic Neo";
	--content-width: 1000px;
}
body {
	position: relative;;
	width: 100vw;
	height: 100vh;
	font-family: var(--font-en), var(--font-kr), 300;
	overflow: hidden;
	margin: 0;
}
#root {
	width: 100%;
	height: 100%;
	font-family: var(--font-en);
	font-weight: 100;
	overflow: scroll;
	margin: 0;
}
div {
	box-sizing: border-box;
}
.main {
	position: relative;
	width: 100%;
    min-height: calc(100% - 160px);
	margin: 0 auto;
	overflow: auto;
}
.main > * {
	width: 100%;
	max-width: var(--content-width);
}
.active {
	font-family: var(--font-en-bold);
}
.hidden {
	display: none!important;
}